exports = module.exports = require("../../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.47.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n.footer_footer_n3soo {\n    padding: 10px 0;\n    margin-top: 10px;\n    border-top: 2px solid var(--ui-black-transparent);\n}\n\n.footer_footer_n3soo a {\n    font-weight: bold;\n    text-decoration: none;\n}\n\n.footer_footer-content_2dnpC {\n    max-width: 600px;\n    margin: auto;\n}\n\n.footer_footer-text_DMIVv {\n    text-align: center;\n    margin: 5px 0 10px 0;\n}\n\n.footer_footer-columns_2KBNo {\n    display: flex;\n    justify-content: center;\n    justify-items: center;\n    flex-wrap: wrap;\n}\n\n.footer_footer-section_1zkbk {\n    display: flex;\n    flex-direction: column;\n    width: 200px;\n    margin-bottom: 10px;\n}\n\n.footer_footer-section_1zkbk > * {\n    margin-bottom: 10px;\n}", ""]);

// exports
exports.locals = {
	"footer": "footer_footer_n3soo",
	"footer-content": "footer_footer-content_2dnpC",
	"footerContent": "footer_footer-content_2dnpC",
	"footer-text": "footer_footer-text_DMIVv",
	"footerText": "footer_footer-text_DMIVv",
	"footer-columns": "footer_footer-columns_2KBNo",
	"footerColumns": "footer_footer-columns_2KBNo",
	"footer-section": "footer_footer-section_1zkbk",
	"footerSection": "footer_footer-section_1zkbk"
};