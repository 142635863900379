const guiColors = {
    'motion-primary': '#ff8200',
    'motion-primary-transparent': '#ff8200e6',
    'motion-tertiary': '#e67700',

    'looks-secondary': '#ff8200',
    'looks-transparent': '#ff820059',
    'looks-light-transparent': '#ff820026',
    'looks-secondary-dark': 'rgba(209, 108, 0, 1)',
    'error-primary': 'rgba(255, 0, 0, 1)',

    'data-primary': 'rgba(0, 171, 17, 1)',
};

const blockColors = {
    checkboxActiveBackground: 'rgba(255, 130, 0, 1)',
    checkboxActiveBorder: 'rgba(230, 119, 0, 1)'
};

export {
    guiColors,
    blockColors
};

// $motion-primary: rgba(255, 130, 0, 1);
// $motion-tertiary: rgba(230, 119, 0, 1);
// $motion-transparent: rgba(255, 130, 0, 0.35); /* 35% transparent version of motion-primary */
// $motion-light-transparent: hgba(255, 130, 0, 0.15); /* 15% transparent version of motion-primary */
// $ui-modal-overlay: var(--ui-modal-overlay, rgba(255, 130, 0, 0.9)); /* 90% transparent version of motion-primary */
// $drop-highlight: rgba(255, 130, 0, 1); /* lighter than motion-primary */

