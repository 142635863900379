exports = module.exports = require("../../node_modules/.pnpm/css-loader@1.0.1_webpack@4.47.0/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* overridden by src/lib/themes/guiHelpers.js */\n\n* {\n    box-sizing: border-box;\n}\n\nbody {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    margin: 0;\n    padding: 0;\n    background: var(--page-background);\n    color: var(--page-foreground);\n}\n\na {\n    color: var(--link-color);\n}\n\n.info-page_main_1S4oJ section {\n    max-width: 900px;\n    margin: auto;\n    margin-bottom: 30px;\n}\n\n.info-page_header-container_NiGzz {\n    color: white;\n    background-color: var(--motion-tertiary);\n    padding: 20px 0;\n    text-align: center;\n    margin-bottom: 30px;\n}\n\n.info-page_header-container_NiGzz a {\n    color: white;\n}\n\n.info-page_header-text_2A4h- {\n\n}\n\n.info-page_users_3SWlg {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.info-page_user-image_3SaMq {\n    margin-right: 12px;\n}\n\n.info-page_user_1eYBE {\n    display: flex;\n    align-items: center;\n    width: 300px;\n    padding: 4px;\n    border-radius: 4px;\n    font-size: 1.25rem;\n    color: inherit !important;\n    text-decoration: none;\n    transition: background .2s;\n}\n\n.info-page_user_1eYBE:link:hover {\n    background: var(--ui-black-transparent);\n}\n", ""]);

// exports
exports.locals = {
	"main": "info-page_main_1S4oJ",
	"header-container": "info-page_header-container_NiGzz",
	"headerContainer": "info-page_header-container_NiGzz",
	"header-text": "info-page_header-text_2A4h-",
	"headerText": "info-page_header-text_2A4h-",
	"users": "info-page_users_3SWlg",
	"user-image": "info-page_user-image_3SaMq",
	"userImage": "info-page_user-image_3SaMq",
	"user": "info-page_user_1eYBE"
};