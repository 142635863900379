const guiColors = {
    'motion-primary': '#013ea8',
    'motion-primary-transparent': '#013ea8e6',
    'motion-tertiary': '#012d7a',

    'looks-secondary': '#013ea8',
    'looks-transparent': '#013ea859',
    'looks-light-transparent': '#013ea826',
    'looks-secondary-dark': 'rgba(218, 99, 98, 1)',
    'error-primary': 'rgba(255, 0, 0, 1)',

    'menu-bar-background': 'rgba(255, 255, 255, 1)',
    'menu-bar-foreground': 'rgba(0, 0, 0, 1)',

    'icon-filter': 'invert(100%)'
};

const blockColors = {
    checkboxActiveBackground: 'rgba(255, 130, 0, 1)',
    checkboxActiveBorder: 'rgba(230, 119, 0, 1)'
};

export {
    guiColors,
    blockColors
};

// $motion-primary: rgba(255, 130, 0, 1);
// $motion-tertiary: rgba(230, 119, 0, 1);
// $motion-transparent: rgba(255, 130, 0, 0.35); /* 35% transparent version of motion-primary */
// $motion-light-transparent: hgba(255, 130, 0, 0.15); /* 15% transparent version of motion-primary */
// $ui-modal-overlay: var(--ui-modal-overlay, rgba(255, 130, 0, 0.9)); /* 90% transparent version of motion-primary */
// $drop-highlight: rgba(255, 130, 0, 1); /* lighter than motion-primary */

